<template>
  <div>
  </div>
</template>
<script>
import store from "@store";

export default {
  data() {
    return {
      credentials: {
        email: '',
        password: '',
        remember_me: false,
      },
      origin:window.location.origin,
      loadingLogin: false,
      pw:this.$route.params.pw,
      action:this.$route.params.action,
      review_id:this.$route.params.id ?? null,
      token:this.$route.params.token ?? null,
      type:this.$route.params.type ?? null,
      add:this.$route.params.add ?? null
    }
  },
  mounted() {
    let user = store.getters['auth/user'];
    if(user){
      this.processRedirects();
    }else{
      this.handleLogin();
    }
  },
  methods: {
    handleLogin() {
      this.loadingLogin = true
      this.$store
        .dispatch('auth/autologin', {token:this.pw})
        .then(() => {
          this.processRedirects();
        })
        .catch(() => {
          this.loadingLogin = false
        })
    },
    processRedirects(){
      switch(this.action){
        case 'setup':
          this.$router.push({name:'connect.google'});
          break;
        case 'approve':
          this.$router.push({name:'business.process-review',query:{action:'approve'}, params:{id:this.review_id}});
          break;
        case 'rewrite':
          this.$router.push({name:'business.process-review',query:{action:'rewrite'}, params:{id:this.review_id}});
          break;
        case 'reject':
          this.$router.push({name:'business.process-review',query:{action:'reject'}, params:{id:this.review_id}});
          break;
        default:
          this.$router.push({name:'business.social-posts', params:{token:this.token, type:this.type, add:this.add}});
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.auth-container {
  width: 100%;
  max-width: 615px;
  margin: 0 auto;
  margin-top: 15vh;
  .custom-control-label {
    &:before,
    &:after {
      width: 20px;
      height: 20px;
      top: 0px;
    }
  }
  .input-group-text {
    padding: 5px 10px;
  }
  .login-header{
    background: #1e3260;
  }
}
</style>
